var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "ejs-grid",
        {
          ref: "dailyWorkAvailability-grid",
          attrs: {
            id: "dailyWorkAvailability-grid",
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            filterSettings: _vm.filterOptions,
            sortSettings: _vm.sortOptions,
            toolbar: _vm.toolbarOptions,
            pageSettings: _vm.pageSettings,
            editSettings: _vm.editSettings,
            commandClick: _vm.commandClick,
            toolbarClick: _vm.toolbarClick
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: { field: "OfficeId", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "OfficeName",
                  headerText: _vm.$t("offices.office"),
                  width: "100",
                  visible: _vm.hasMultipleOffices
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EmployeeId",
                  headerText: _vm.$t("employees.employees"),
                  width: "130",
                  type: "number",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "ValidFrom",
                  headerText: _vm.$t("shared.validFrom"),
                  width: "130",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "ValidTo",
                  headerText: _vm.$t("shared.validTo"),
                  width: "130",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "IsDayOff",
                  headerText: _vm.$t("isDayOff.title"),
                  width: "130",
                  type: "boolean",
                  filter: { type: "CheckBox" },
                  valueAccessor: _vm.yesNoGridValueMapper
                }
              }),
              _c("e-column", { attrs: { width: "50", commands: _vm.commands } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }